import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main home page */}
        <Route path="/" element={<HomePage />} />

        {/* Route that LinkedIn redirects to with the code */}
        <Route path="/home" element={<Home />} />

        {/* Privacy Policy page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
