import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Parse the query string to get the 'code' parameter
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      console.log("LinkedIn Authorization Code:", code);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "authorization_code");
      urlencoded.append("code", code);
      urlencoded.append("redirect_uri", "https://commentwave.netlify.app/home");
      urlencoded.append("client_id", "867vfjx7qd0xmu");
      urlencoded.append("client_secret", "cR2P9WP35Gnr90vj");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://www.linkedin.com/oauth/v2/accessToken", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          console.log("Access Token Response:", result);
        })
        .catch((error) => {
          console.error("Error fetching access token:", error);
        });
    } else {
      console.log("Authorization code not found.");
    }
  }, [location]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold">Comment Wave</h1>
      <p>More to Come</p>
    </div>
  );
};

export default Home;
