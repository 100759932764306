import React, { useRef, useEffect } from "react";

const HomePage = () => {
  const h1Ref = useRef(null);

  useEffect(() => {
    const h1Element = h1Ref.current;
    const parentElement = document.getElementById("test");

    const handleDragStart = (e) => {
      const rect = h1Element.getBoundingClientRect();
      e.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          offsetX: e.clientX - rect.left,
          offsetY: e.clientY - rect.top,
        })
      );
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const data = JSON.parse(e.dataTransfer.getData("text/plain"));
      const x = e.clientX - data.offsetX;
      const y = e.clientY - data.offsetY;
      h1Element.style.position = "absolute";
      h1Element.style.left = `${x}px`;
      h1Element.style.top = `${y}px`;
    };

    h1Element.addEventListener("dragstart", handleDragStart);
    parentElement.addEventListener("dragover", handleDragOver);
    parentElement.addEventListener("drop", handleDrop);

    return () => {
      h1Element.removeEventListener("dragstart", handleDragStart);
      parentElement.removeEventListener("dragover", handleDragOver);
      parentElement.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div className="relative h-screen bg-gray-100 overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="vid2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay to darken the video */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

      {/* Navbar */}
      <nav className="relative bg-gray-800 p-4 z-10">
        <div className="container mx-auto flex justify-between items-center">
          <div>
            <a href="/" className="text-white hover:text-gray-300">
              <img
                src="new.png"
                alt="MyWebsite Logo"
                className="inline-block mr-2 w-15 h-10"
              />
            </a>
          </div>
          <div className="flex space-x-4">
            {/* <a
              href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=867vfjx7qd0xmu&redirect_uri=https://commentwave.live/home&state=foobar&scope=w_member_social%20r_basicprofile"
              className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
            >
              Sign In
            </a> */}

            <a
              href="https://www.linkedin.com/oauth/v2/authorization?client_id=867vfjx7qd0xmu&redirect_uri=https://commentwave.netlify.app/home&response_type=code&scope=w_member_social%20r_basicprofile%20w_member_social_feed&state=foobar"
              className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
            >
              Sign In
            </a>
            <a
              href="/privacy-policy"
              className="text-white hover:text-gray-300"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div
        className="relative flex justify-center items-center h-full z-10"
        id="test"
      >
        <h1
          ref={h1Ref}
          className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-rainbow bg-size-200 animate-gradient"
          draggable="true"
        >
          Comment Wave
        </h1>
      </div>
    </div>
  );
};

export default HomePage;
