// src/components/PrivacyPolicy.jsx
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div>
            <a href="/" className="text-white hover:text-gray-300">
              <img
                src="new.png"
                alt="CommentWave Logo"
                className="inline-block mr-2 w-15 h-10"
              />
            </a>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=771og5bdx4gkwt&redirect_uri=http://localhost:3000/home&state=foobar&scope=profile%20email"
              className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
            >
              Sign In
            </a>
            <a
              href="/privacy-policy"
              className="text-white hover:text-gray-300"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mx-auto p-8 bg-cyan-600 shadow-md rounded-lg mt-8">
        <h1 className="text-4xl font-bold mb-6 text-center">
          Privacy Policy for CommentWave
        </h1>
        <p className="text-gray-600 mb-4 text-right">
          Last updated: Mon Sep 16 2024
        </p>

        <div className="space-y-6">
          <section>
            <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
            <p>
              Welcome to CommentWave ("we", "our", or "us"). We respect your
              privacy and are committed to protecting your personal data. This
              privacy policy will inform you about how we look after your
              personal data when you visit our website and use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              2. The Data We Collect
            </h2>
            <p>
              We prioritize your privacy and collect only the minimum data
              necessary to provide our services:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Identity Data: first name, last name, username</li>
              <li>Contact Data: email address</li>
              <li>
                Technical Data: IP address, login data, browser type and version
              </li>
              <li>
                Usage Data: information about how you use our website and
                services
              </li>
            </ul>
            <p>
              We do not store passwords or telephone numbers. Our aim is to
              protect your data, not to collect unnecessary information.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              3. How We Use Your Data
            </h2>
            <p>CommentWave uses the collected data for various purposes:</p>
            <ul className="list-disc list-inside ml-4">
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li>To provide customer support</li>
              <li>
                To gather analysis or valuable information so that we can
                improve our Service
              </li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent, and address technical issues</li>
              <li>
                To provide you with news, special offers, and general
                information about other goods, services, and events which we
                offer (unless you have opted not to receive such information)
              </li>
            </ul>
            <p>
              We use your data to enhance your experience, not for any
              unauthorized purposes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">4. Data Retention</h2>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations, resolve disputes, and enforce our legal
              agreements and policies.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
            <p>
              The security of your data is our top priority. While no method of
              transmission over the Internet or electronic storage is 100%
              secure, we implement commercially acceptable measures to protect
              your Personal Data, including:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Use of encryption for data transmission</li>
              <li>Regular security audits</li>
              <li>Strict access controls for our employees</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              6. Service Providers
            </h2>
            <p>
              We may employ third-party companies and individuals to facilitate
              our Service ("Service Providers"), provide the Service on our
              behalf, perform Service-related services or assist us in analyzing
              how our Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">7. Transfer of Data</h2>
            <p>
              Your information, including Personal Data, may be transferred to
              computers located outside of your state, province, country, or
              other governmental jurisdiction where the data protection laws may
              differ from those of your jurisdiction.
            </p>
            <p>
              We ensure that any transfer of data is done securely and in
              compliance with applicable data protection laws.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              8. Disclosure of Data
            </h2>
            <p>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>
                For Law Enforcement: Under certain circumstances, we may be
                required to disclose your Personal Data if required to do so by
                law or in response to valid requests by public authorities.
              </li>
              <li>
                Business Transaction: If we are involved in a merger,
                acquisition or asset sale, your Personal Data may be
                transferred.
              </li>
              <li>
                With Your Consent: We may disclose your personal information for
                any other purpose with your consent.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              9. Your Data Protection Rights
            </h2>
            <p>
              Depending on your jurisdiction, you may have certain data
              protection rights. These may include:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>
                The right to access, update or delete your personal information
              </li>
              <li>The right of rectification</li>
              <li>The right to object to processing of your personal data</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
            <p>
              We respect your rights and will respond to any requests regarding
              your personal data promptly.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">
              10. Changes to This Privacy Policy
            </h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the "Last updated" date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-2">11. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </p>
            <p>
              CommentWave
              <br />
              Email:{" "}
              <a
                href="mailto:help@CommentWave.live"
                className="text-blue-500 hover:underline"
              >
                help@CommentWave.live
              </a>
              <br />
              Alternative Email:{" "}
              <a
                href="mailto:eshandas@commentwave.live"
                className="text-blue-500 hover:underline"
              >
                eshandas@commentwave.live
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
